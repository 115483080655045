import React, { FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Link as MuiLink } from '@material-ui/core';

interface Props {
  className?: string;
  to: string
}

const Link: FC<Props> = React.forwardRef((props, ref: any) => (
  <MuiLink component={GatsbyLink} {...props} ref={ref} />
));

export default Link;
