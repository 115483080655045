import React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import { graphql } from 'gatsby';
import Text from 'components/shared/text';
import Link from 'components/shared/link';
import Hat from 'components/shared/hat';
import { css } from 'styled-components';
import theme from 'src/shared/theme';
import { XS } from 'src/shared/media-query';
import StylesWrapper from 'components/shared/layout/styles-wrapper';

interface GrapqlData {
  file: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}

const NotFoundPage = ({ data }: { data: GrapqlData }) => (
  <StylesWrapper>
    <Hat pageName="Neexistující stránka" />
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: ${theme.spacing(8)};

        @media ${XS} {
          padding: ${theme.spacing(20)};
        }
      `}
    >
      <Text variant="h1" gutterBottom>
        Stránka neexistuje
      </Text>

      <Text
        css={css`
          margin-bottom: ${theme.spacing(8)};
        `}
      >
        Ztratili jste se? Zkuste najít co hledáte na
        {` `}
        <Link to="/">hlavní stránce.</Link>
      </Text>

      <Img
        fluid={data.file.childImageSharp.fluid}
        css={css`
          width: 100%;
          max-height: 70vh;
        `}
        imgStyle={{ objectPosition: `top center`, objectFit: `contain` }}
      />
    </div>
  </StylesWrapper>
);

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "charles-deluvio-zqhe4qjVTJI-unsplash.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
