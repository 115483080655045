import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

interface Props {
  className?: string;
  pageName?: string;
  pageDescription?: string;
  pageImage?: string;
  pageArticle?: string;
}

const Hat: FC<Props> = props => {
  const { pathname } = useLocation();
  const { site, markdownRemark } = useStaticQuery(query);
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata;

  const [mobilePhone, officePhone] = markdownRemark.frontmatter.phones;

  const seo = {
    title: defaultTitle,
    description: props.pageDescription || defaultDescription,
    image: `${siteUrl}${props.pageImage || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet
      title={props.pageName}
      defaultTitle={seo.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{ lang: `cs` }}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <link rel="alternate" href={siteUrl} hrefLang="x-default" />
      <meta
        name="keywords"
        content="veterinar,trutnov,veterina,veterinář,veterinarni klinika,veterinární klinika,veterinary clinic,veterinarian,vet,veterinary,tierazt,veterinärärztlich"
      />

      {seo.url && <meta property="og:url" content={seo.url} />}
      {(props.pageArticle ? true : null) && (
        <meta property="og:type" content="article" />
      )}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": ${siteUrl},
          "name": ${seo.title},
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": ${officePhone || mobilePhone},
            "contactType": "Office phone"
          }
        }
      `}
      </script>

      {props.children}
    </Helmet>
  );
};

export default Hat;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
      }
    }
    markdownRemark(frontmatter: { id: { eq: "welcome-section" } }) {
      frontmatter {
        phones
      }
    }
  }
`;
