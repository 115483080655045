import React, { FC } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from 'src/shared/theme';

const StylesWrapper: FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <StyleSheetManager disableVendorPrefixes>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyleSheetManager>
  </MuiThemeProvider>
);

export default StylesWrapper;
